<template>
  <form @submit="submit">
    <!-- total points on the quiz -->
    <div v-if="feedback.total_points" class="p-1">
      <h2>Ilość zdobytych punktów: {{ feedback.total_points }}/{{ maxPoints }}</h2>
    </div>
    <div
        class="questions my-1 border-bottom"
        v-for="(question, idx) in questions"
        :key="idx"
    >
      <Radiogroup
          :question="question"
          v-model="answers[idx]"
          :feedback="feedback[question.id]"
          :is_submitted="is_submitted"
      ></Radiogroup>
    </div>
    <button v-if="!is_submitted" class="btn btn-success">Prześlij odpowiedzi</button>
    <div v-if="is_submitted"
         class="alert alert-success p-2"
         role="alert">
      Twój wynik to: {{ totalPoints }}/{{ maxPoints }} punkty
    </div>

    <!-- all answers -->
    <div class="mt-2">
      <b-list-group>
        <b-list-group-item
            v-for="(answer, answer_date,idx) in all_answers"
            :key="answer_date"
            class="flex-column align-items-start"
        >
          <div class="row mb-1 mt-2">
            <div class="col-sm-4 mb-1 mb-sm-0">
              Data wypełnienia testu<br />
              <strong>{{ answer_date }}</strong>
            </div>
            <div class="col-sm-4 mb-1 mb-sm-0 text-sm-center">
              Ilość punktów: <br />
              <strong>{{ answer.total_points }}/{{ maxPoints }} ( {{ calculatePercentage(answer) }}% )</strong>
            </div>
            <div class="col-sm-4 mb-1 mb-sm-0 text-sm-right">
              <button v-b-modal="'modal-'+idx" type="button" class="btn btn-outline-primary btn-sm">
                Pokaż odpowiedzi
              </button>
              <div>
                <b-modal :id="'modal-'+idx" :title="answer_date">
                  <QuizAnswer :quiz="quiz" :answer="answer"></QuizAnswer>
                </b-modal>
              </div>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </form>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import Radiogroup from "./Radiogroup.vue";
import QuizAnswer from "./QuizAnswer.vue";
import {BListGroup, BListGroupItem} from "bootstrap-vue";

export default {
  props: ["quiz", "refillable"],
  components: {
    Radiogroup,
    QuizAnswer,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      questions: this.quiz.questions,
      is_submitted: this.quiz.user_done,
      answers: [],
      feedback: {},
      all_answers: {},
    };
  },
  async mounted() {
    await this.loadAnswers();
  },
  computed: {
    maxPoints() {
      return this.questions.length;
    },
    totalPoints() {
      let total = 0

      if(this.is_submitted)
      {
        for (var question of this.questions) {
          total += this.feedback[question.id].points;
        }
      }

      return total.toFixed(0);
    },
  },

  watch: {},
  methods: {
    calculatePercentage(answer) {
      return Math.round(((answer.total_points / this.maxPoints) * 100))
    },

    async loadAnswers() {
      this.$http.get(`https://api.${this.$store.state.domain}/api/quizanswer/show/${this.quiz.id}`).then(response => {
        this.all_answers = response.data;
      });
    },
    async submit(e) {
      // do not refresh on submit
      e.preventDefault();

      // check that user completed the quiz
      this.is_submitted = true;

      // make a data object
      let data = {
        quiz_id: this.quiz.id,
        answers: this.answers,
      };

      this.$http.post(`https://api.${this.$store.state.domain}/api/quizanswer/create`, data).then(response => {
        this.feedback = response.data;
        this.loadAnswers();
      });
    },
  },
};
</script>
<style></style>
